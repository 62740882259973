@import url('https://fonts.googleapis.com/css2?family=Kreon:wght@300..700&display=swap');

*{
  padding: 0px;
  margin: 0px;
}

.kreon {
  font-family: "Kreon", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.hmeCon{
  background-image: url('./Assets/Backgrounds/homebg.png');
  background-repeat: no-repeat;
  background-size: cover;
}